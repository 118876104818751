import type { FC, SVGProps } from 'react';

export type TIconTypes =
  | 'doneOutline16x16'
  | 'chevronUpOutline16x16'
  | 'chevronDownOutline16x16'
  | 'chevronRight'
  | 'arrowLeftOutline24x24'
  | 'checkCircleOutline24x24'
  | 'checkCircleOutlineInactive24x24'
  | 'checkCircleOutlineActive24x24'
  | 'closeOutline24x24'
  | 'errorOutline24x24'
  | 'burgerMenuOutline24x24'
  | 'logo-small'
  | 'accentInfo'
  | 'defaultInfo'
  | 'userOutline24x24'
  | 'closeOutline16x16'
  | 'infoOutline24x24'
  | 'infoOutline16x16'
  | 'timerOutline24x24'
  | 'timerOutline16x16'
  | 'linesOutline24x24'
  | 'linkInOutline16x16'
  | 'linkInOutline24x24'
  | 'crownOutline24x24'
  | 'logoutOutline16x16'
  | 'lockOutline24x24'
  | 'postOutline24x24'
  | 'starFilled24x24'
  | 'errorFilled24x24'
  | 'errorFilled16x16'
  | 'moderator'
  | 'mainModerator'
  | 'round22x22'
  | 'fileOutline24x24'
  | 'fileOutlineWithStar24x24'
  | 'union16х16'
  | 'delete24x24'
  | 'plusOutline24x24'
  | 'chevronRightOutline16x16'
  | 'arrowRight'
  | 'arrowLeft'
  | 'authorSwitchOutline24x24'
  | 'editOutline24x24'
  | 'kebabOutline24x24'
  | 'kebabOutline24x24Disabled'
  | 'step1'
  | 'step2'
  | 'step3'
  | 'linkOffOutline24x24'
  | 'openTab16x16'
  | 'calendar24x24'
  | 'closeCircleOutline24x24'
  | 'discount'
  | 'settingIcon24x24'
  | 'settingIconDisabled24x24'
  | 'check'
  | 'dice'
  | 'circled80x80'
  | 'reloadOutline24x24'
  | 'dragSortingOutline16x16'
  | 'speaker16x16'
  | 'dragSortingOutline24x24'
  | 'verifiedFilled16x16'
  | 'likeOutline24x24'
  | 'dislikeOutline24x24'
  | 'answers24x24'
  | 'subtract'
  | 'search'
  | 'logoutOutline24x24'
  | 'user24x24'
  | 'star24x24'
  | 'mask24x24'
  | 'faq24x24'
  | 'chart24x24'
  | 'notification'
  | 'infoFill16x16'
  | 'royaltyOutline24x24'
  | 'createAudioOutline24x24'
  | 'discountOutline24x24'
  | 'moveOutline24x24'
  | 'reviewsOutline24x24'
  | 'withdrawOutline24x24'
  | 'deleteOutline24x24'
  | 'aiOutline16x16'
  | 'bagOutline24x24'
  | 'discount24x24'
  | 'readerOutline24x24'
  | 'syncOutline24x24'
  | 'eyeOpen'
  | 'eyeClosed'
  | 'copy'
  | 'checkSuccessOutline'
  | 'checkSuccessInactive'
  | 'diamondNew'
  | 'polygonBig'
  | 'polygonSmall'
  | 'starBig'
  | 'cup24x24'
  | 'polygonMarker'
  | 'polygonMarkerOutline'
  | 'undo'
  | 'redo'
  | 'help'
  | 'filterOutline'
  | 'lightning'
  | 'chevronUpNew24'
  | 'seriesOutline24x24'
  | 'book'
  | 'chart'
  | 'money'
  | 'shield'
  | 'star32x32'
  | 'aiCountOutline16x16';

export interface IIconProps extends Omit<SVGProps<SVGSVGElement>, 'style'> {
  icon: TIconTypes;
  size?: 16 | 24 | number;
  color?: string;
}

export const Icon: FC<IIconProps> = props => {
  const { icon, size = 16, color, ...restProps } = props;
  return (
    <svg width={size} height={size} style={{ color: color }} {...restProps}>
      <use xlinkHref={`#${icon}`} />
    </svg>
  );
};
